<!--客服管理系统-->
<template>
  <div class="customer-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="客服管理系统" @btnClick="bannerClick"
                  imgStyle="width:334px;height:350px" :imgSrc="`${require('@/assets/img/solution/客服管理系统.png')}`"
                  contentStyle="width:90%"/>
    <!--传统客服管理面临的痛点-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">传统客服管理面临的痛点</p>
        <div class="module-col">
          <el-row :gutter="20">
            <template v-for="list of dataList">
              <el-col :span="12">
                <div class="col-content">
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--客服管理四大核心能力-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">客服管理四大核心能力</p>
        <div class="module--content">
          <div class="module-left">
            <div class="module-li">
              <p class="title">价值转化评估</p>
              <p class="small-p">多角度全方位分析数据，精细化客户服务</p>
              <p class="content">1、支持坐席工作量/会话量/满意度等报表导出，辅助分析业绩指标</p>
              <p class="content">2、实时监控用户排队、坐席状态等情况，便于及时调整优化</p>
              <p class="content">3、质检结果全面统计和分析，帮助企业不断优化改善服务能力</p>
            </div>
            <div class="module-li li-2">
              <p class="title">API开放平台能力</p>
              <p class="small-p">企业各渠道业务数据打通，统一管理客户资源</p>
              <p class="content">1、连接主流营销推广平台，获取线索自动导入系统</p>
              <p class="content">2、对接企业内部自有业务系统，平台同步记录客户资料和历史动态</p>
              <p class="content">3、更多CRM、ERP、OA系统对接，满足客户定制化需求</p>
            </div>
          </div>
          <div class="module-center"/>
          <div class="module-right">
            <div class="module-li">
              <p class="title">即时沟通对话</p>
              <p class="small-p">统一工作台收发各渠道客户咨询，沟通更及时</p>
              <p class="content">1、打通微信、微信群与客服中心链接，针对性开展群聊服务</p>
              <p class="content">2、支持坐席端预先读取访客正在输入的信息，提前掌握客户需求</p>
              <p class="content">3、根据客户输入关键词推荐话术，支持一键发送给客户</p>
            </div>
            <div class="module-li li-2">
              <p class="title">客服能力升级</p>
              <p class="small-p">机器人辅助或代替人工进行客户服务，服务更高效</p>
              <p class="content">1、在线/群服务机器人协助人工解决 90% 常见重复问题</p>
              <p class="content">2、坐席高峰期全忙时，机器人分流自动接管客户咨询</p>
              <p class="content">3、节假日或夜间值班期间，机器人独当一面，7×24h服务响应</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--一个平台连接企业营销服务全触点-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title">一个平台连接企业营销服务全触点</p>
        <div class="module-img"/>
      </div>
    </div>
    <!--帮企数智云客服管理优势-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云客服管理优势</p>
        <div class="module-col">
          <el-row :gutter="30">
            <template v-for="list of dataList2">
              <el-col :span="8">
                <div class="col-content">
                  <div class="col-img"
                       :style="'background: url('+require('@/assets/img/solution/'+list.icon)+') no-repeat'"/>
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <new-cloude-footer/>
    <applyTrial ref="dialog" :servicePlatform="'客服管理系统'"/>
  </div>
</template>
<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";
export default {
  name: "customer",
  components: {NewCloudeFooter, NewCloudeHeader,headerBanner,applyTrial},
  data() {
    return {
      bannerContent: '人工智能客服自从开始进入市场后，受到了各行各业的认可，其功能可以在银行、电商、地产、家居、汽车等灵活应用，满足企业的运营和业务需求。到目前，从使用企业反馈的数据情况看，智能客服可以降低人工消耗，把重复性问题，简单性咨询或是初次联系的客户交予智能客服，让人工客服集中注意力服务后续意向性更强的客户。智能客服确实是可以有效提高工作效率，满足企业的业务需求，提高交易量，提升企业运营效率。',
      dataList:[
        {title: '内外协同难', introduce: '传统模式下，客服系统与内部办公系统无法共享客户信息，协同处理阻碍大。客户无法对提交的请求进行跟踪，也无法对服务做出评价。'},
        {title: '移动平台整合难', introduce: '外部客户缺少统一的客服平台对接门户，同时满足app、微信公众号、小程序等移动应用开发周期长、成本高，多移动终端数据难整合、难同步。'},
        {title: '服务能力参差不齐', introduce: '客服人员的服务情况因人的能力和水平而有所不同，会直接影响到服务过程的服务质量和客户的服务感受。'},
        {title: '服务形式和内容单一', introduce: '客户遇到问题只能主动联系客服，没有自行解决问题的途径，完全依赖人员处理效率，无法产生二次收益。'},
      ],
      dataList2:[
        {title: '全网多渠道接入', introduce: '支持官网、APP、微信/微信群、公众号、微博邮件等服务渠道接入', icon: '全网多渠道接入.png'},
        {title: '网站主动会话弹窗', introduce: '主动向正在浏览网页的用户发起会话弹窗邀请，抓住潜在商机', icon: '网站主动会话弹窗.png'},
        {title: '来源渠道识别', introduce: '识别用户访问网站的媒体渠道，如来源平台、关键词等信息，实现精准营销', icon: '来源渠道识别.png'},
      ]
    }
  },
  methods:{
    bannerClick(){
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.customer-container{
  background: #FFFFFF;
  .module-1 {
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .col-content {
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 20px;
          height: 80px;
          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            padding-left: 10px;
            border-left: 4px solid #1935DE;
            margin-block-end: 15px;
            margin-block-start: 0px;
            line-height: 18px;
          }
          .introduce {
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 0px;
          }
        }
      }
    }
  }
  .module-2 {
    background: #F7F8FE;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module--content {
        display: flex;
        justify-content: space-between;

        .module-center {
          width: 364px;
          height: 269px;
          background: url("~@/assets/img/solution/客服管理四大核心能力.png");
          background-size: 100% 100%;
        }

        .module-left {
          width: 390px;
          margin-top: -10px;

          .module-li {
            text-align: end;
            .title {
              margin-block-start: 0px;
              margin-block-end: 10px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
            .content {
              margin-block-start: 0px;
              margin-block-end: 5px;
              font-size: 16px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
            .small-p{
              color: #999999;
              font-size: 14px;
              font-family: 'SourceHanSansCN';
              margin-block-start: 0px;
              margin-block-end: 0px;
            }
          }

          .li-2 {
            margin-top: 30px;
          }
        }

        .module-right {
          width: 400px;
          margin-top: -10px;
          .module-li {
            text-align: left;
            .title {
              margin-block-start: 0px;
              margin-block-end: 10px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
            .content {
              margin-block-start: 0px;
              margin-block-end: 5px;
              font-size: 16px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
            .small-p{
              color: #999999;
              font-size: 14px;
              font-family: 'SourceHanSansCN';
              margin-block-start: 0px;
              margin-block-end: 0px;
            }
          }
          .li-2 {
            margin-top: 53px;
          }
        }
      }
    }
  }
  .module-3 {
    background: #FFFFFF;
    height: 713px;
    width: 100%;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-img {
        height: 536px;
        width: 1200px;
        background: url("~@/assets/img/solution/一个平台连接企业营销服务全触点.png") no-repeat;
        background-size: 100% 100%;
        float: right;
      }
    }
  }
  .module-4 {
    background: #F7F8FE;
    height: 432px;
    width: 100%;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px 20px;
      .col-content {
        background: #FFFFFF;
        padding: 30px 20px;
        .default-title {
          font-size: 18px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          margin-block-end: 15px;
          margin-block-start: 10px;
        }
        .introduce {
          font-size: 16px;
          font-family: "SourceHanSansCN";
          color: #333333;
          margin-block-end: 0px;
          margin-block-start: 0px;
        }
        .col-img {
          width: 30px;
          height: 31px;
          background-size: 100% 100% !important;
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
